/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import ShoppingCartIcon from '@heroicons/react/24/solid/ShoppingCartIcon';
import cx from 'classnames';
import BadgeCounter from '@common_badgecounter';
import Popover from '@common_popover';
import useMediaQuery from '@hook/useMediaQuery';
import CartPopover from './cartPopover';
import ProductAddedPopover from './productAddedPopover';

const WithLink = (props) => {
    const {
        cartData, handleLink, setOpenCart, openState, handleDispatch,
    } = props;
    const { isMobile } = useMediaQuery();

    return (
        <>
            <div
                onMouseEnter={() => {
                    if (!openState.isCartOpen && !isMobile) {
                        handleDispatch({ type: 'OPEN_CART' });
                    }
                }}
                onMouseLeave={() => handleDispatch({ type: 'CLOSE_CART' })}
            >
                <Popover
                    content={(
                        <CartPopover
                            open={openState.isCartOpen}
                            setOpen={setOpenCart}
                            openState={openState}
                            handleDispatch={handleDispatch}
                            {...props}
                        />
                    )}
                    open={openState.isCartOpen}
                    setOpen={setOpenCart}
                    className={cx(
                        'w-[160px]', 'top-[37px]', 'right-[-15px]',
                        'after: after:w-10 after:h-10 after:absolute after:top-[-10px] after:right-[9px]',
                    )}
                    contentClassName={cx('!rounded-[5px] !border-neutral-100 shadow-type-1 max-h-fit')}
                >
                    <div
                        className={cx('group hover:cursor-pointer')}
                        onClick={handleLink}
                    >
                        <BadgeCounter
                            className={cx(
                                '!absolute',
                                '!top-0',
                                '!text-[13px]',
                                'font-medium',
                                'w-[18px]',
                                'h-[18px]',
                                'bg-yellow-300',
                                'right-0',
                                '!text-neutral-black',
                                'tablet:max-desktop:right-1',
                            )}
                            value={cartData}
                            showZero={false}
                        >
                            <span className="cart-icon text-neutral-black hover:before:text-yellow-300" />
                            <style jsx>
                                {`
                                .cart-icon:before {
                                    display: block;
                                    content: "\e96d";
                                    font-family: var(--icons-urbanicon-fossil-theme);
                                    font-size: 1.75rem;
                                    line-height: 1.75rem;
                                    width: 1.75rem;
                                    height: 1.75rem;
                                }
                                @media only screen and (max-width: 767.9px) {
                                    .cart-icon:before {
                                        font-size: 25px;
                                        line-height: 25px;
                                        width: 25px;
                                        height: 25px;
                                    }
                                }
                            `}
                            </style>
                        </BadgeCounter>
                    </div>
                </Popover>
            </div>
            <ProductAddedPopover {...props} />
        </>
    );
};

export default WithLink;
